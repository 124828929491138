import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import classNames from "classnames";

const PageTopper = ({
  children,
  className,
  style,
  image,
  alt,
  imgClassName,
}) => {
  return (
    <>
      <Helmet
        bodyAttributes={{
          "data-page-topper": true,
        }}
      />
      <div className="overflow-hidden h-56 md:h-64 lg:h-80">
        {image && alt && (
          <GatsbyImage
            image={getImage(image)}
            alt={alt}
            imgClassName={classNames("!h-56 md:!h-64 lg:!h-80", imgClassName)}
            draggable="false"
          />
        )}
      </div>
      <div className={classNames("container mt-10", className)} style={style}>
        {children}
      </div>
    </>
  );
};

export default PageTopper;
