import React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import { BLOCKS } from "@contentful/rich-text-types";

import Layout from "../../components/layout";
import PageInfo from "../../components/pageInfo";
import PageTopper from "../../components/pageTopper";
import { renderRichText } from "../../utils/renderRichText";

const Index = ({ data: { contentfulPage } }) => {
  let options = {
    renderFuncs: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="text-xl mt-4 mb-0">{children}</p>
      ),
    },
  };

  let rawBody = JSON.parse(contentfulPage.body.raw);
  let currentListItem = 0;
  let list = rawBody.content.reduce((list, node) => {
    let { nodeType, content } = node;
    if (nodeType === BLOCKS.HEADING_2) {
      if (!list[currentListItem]) list[currentListItem] = {};
      list[currentListItem]["label"] = content[0].value;
    }
    if (nodeType === BLOCKS.PARAGRAPH) {
      list[currentListItem]["content"] = renderRichText({ raw: node }, options);
    }
    if (nodeType === BLOCKS.HR) {
      currentListItem++;
    }

    return list;
  }, []);

  return (
    <Layout noContainer={!!contentfulPage.topperImage}>
      <PageInfo title={contentfulPage.title} />
      {contentfulPage.topperImage ? (
        <PageTopper
          image={getImage(contentfulPage.topperImage)}
          alt={contentfulPage.topperImage.title}
          className="mb-20"
        >
          <h1 className="text-6xl text-center mb-10 font-new-tegomin">
            {contentfulPage.title}
          </h1>

          {/* <RenderRichText richText={contentfulPage.body} /> */}

          <FAQList list={list} />
        </PageTopper>
      ) : (
        <>
          <h1 className="text-6xl text-center mb-10 font-new-tegomin">
            {contentfulPage.title}
          </h1>

          {/* <RenderRichText richText={contentfulPage.body} /> */}

          <FAQList list={list} />
        </>
      )}
    </Layout>
  );
};

export const query = graphql`
  query ($contentful_id: String!) {
    contentfulPage(contentful_id: { eq: $contentful_id }) {
      ...PageTemplateFields
    }
  }
`;

export default Index;

const FAQList = ({ list }) => {
  return (
    <Accordion
      allowZeroExpanded={true}
      className="p-4 bg-gray-600 text-studio-alt-white m-auto"
      style={{ maxWidth: 1200 }}
    >
      {list.map(({ label, content }, index) => (
        <AccordionItem
          key={index}
          className={classNames("p-4 border-2 border-studio-charcoal", {
            "mb-4": index + 1 !== list.length,
          })}
        >
          <AccordionItemHeading className="text-2xl font-bold font-new-tegomin">
            <AccordionItemButton>
              <AccordionItemState>
                {(state) => (
                  <AccordionItemIcon state={state}>
                    <span>{label}</span>
                  </AccordionItemIcon>
                )}
              </AccordionItemState>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>{content}</AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

const AccordionItemIcon = ({ children, state }) => {
  return (
    <span>
      <FontAwesomeIcon
        icon={state.expanded ? faChevronDown : faChevronRight}
        className="mr-3"
        style={{ maxHeight: 24 }}
      />
      {children}
    </span>
  );
};
